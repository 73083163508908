import { faBars, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { Portal } from "@radix-ui/react-portal";
import { clsx } from "clsx";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScrollLock } from "usehooks-ts";
import { Icon } from "~/components/utilities/IconVariant";
import { Square } from "~/components/utilities/interactive/Square";
import { NavigationContent } from "~/components/utilities/layout/navigation/Content";
import { NavigationFooter } from "~/components/utilities/layout/navigation/NavigationFooter";
import { useEscapeKeydown } from "~/hooks/use-escape-keydown";
import { useRouteChange } from "~/hooks/use-route-change";

export function MobileNavigation() {
	const { t } = useTranslation();
	const [open, setOpen] = useState<boolean>(false);

	const mainRef = useRef<HTMLDivElement>(null);

	const { lock, unlock } = useScrollLock({ autoLock: false });

	function onOpenChange(open: boolean) {
		setOpen(open);

		if (open) {
			lock();
		} else {
			unlock();
		}
	}

	useRouteChange(() => onOpenChange(false));
	useEscapeKeydown(() => onOpenChange(false));

	return (
		<>
			<Square rounded="full" className="xs:hidden">
				<button
					type="button"
					onClick={() => onOpenChange(!open)}
					title={open ? t("close_menu") : t("open_menu")}
				>
					<Icon icon={faBars} size="lg" />
				</button>
			</Square>

			<Portal asChild>
				<div
					ref={mainRef}
					className={clsx(
						"fixed inset-0 z-2 flex xs:hidden w-full flex-col gap-6 bg-background p-4 motion-safe:transition-transform",
						{ "-translate-x-full": !open },
					)}
				>
					<div className="flex items-center justify-between gap-4">
						<div className="flex items-center gap-x-4 gap-y-1">
							<img src="/images/logo-icon.png" alt={t("app_name")} className="h-12 w-12" />
							<NavigationFooter expanded={false} />
						</div>

						<Square size="large">
							<button type="button" onClick={() => onOpenChange(false)} title={t("close_menu")}>
								<Icon icon={faTimes} size="lg" />
							</button>
						</Square>
					</div>

					<NavigationContent expanded />
				</div>
			</Portal>
		</>
	);
}
