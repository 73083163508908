import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { $api, fetchClient } from "~/api";
import { FastActions } from "~/components/FastActions";
import { Checkbox } from "~/components/utilities/Checkbox";
import { TableCell } from "~/components/utilities/table/Cell";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { useTableProps } from "~/hooks/use-table";
import { TableLayout } from "~/layouts/TableLayout";
import { formatter } from "~/library/utilities";
import { paginationSchema } from "~/types/route";

const searchSchema = paginationSchema.extend({
	filter: z.object({ supplier: z.string().optional() }).optional(),
});

type SearchParams = z.infer<typeof searchSchema>;

const purchaseOrderItemsQueryOptions = (params: SearchParams) => {
	return $api.queryOptions("get", "/api/v1/purchase-orders/items", {
		params: {
			query: {
				page: params.page,
				per_page: params.per_page,
				"filter[supplier]": params.filter?.supplier,
			},
		},
	});
};

export const Route = createFileRoute("/_authenticated/purchase-orders/items/")({
	component: RouteComponent,
	validateSearch: (search) => searchSchema.parse(search),
	loaderDeps: ({ search }) => search,
	loader: ({ context, deps }) =>
		context.queryClient.ensureQueryData(purchaseOrderItemsQueryOptions(deps)),
});

function RouteComponent() {
	const { t, i18n } = useTranslation();
	const navigate = Route.useNavigate();
	const searchParams = Route.useSearch();
	const queryClient = useQueryClient();
	const query = useSuspenseQuery(purchaseOrderItemsQueryOptions(searchParams));
	const items = query.data;

	useCustomDocumentTitle(`${t("purchase_order_items")} (${items.meta.total})`);

	const columns = useMemo(() => {
		const columnHelper = createColumnHelper<(typeof items)["data"][number]>();

		return [
			columnHelper.display({
				id: "checkbox",
				enableHiding: false,
				header: ({ table }) => (
					<Checkbox
						checked={table.getIsAllRowsSelected()}
						indeterminate={table.getIsSomeRowsSelected()}
						onChange={table.getToggleAllRowsSelectedHandler()}
						title={t("select_all")}
					/>
				),
				cell: ({ row }) => (
					<Checkbox
						checked={row.getIsSelected()}
						disabled={!row.getCanSelect()}
						onChange={row.getToggleSelectedHandler()}
						title={t("select_row")}
					/>
				),
			}),
			columnHelper.accessor("id", {
				header: t("id"),
				cell: (info) => <TableCell variant="clipboard" value={info.getValue()} />,
			}),
			columnHelper.accessor("order_item.order.increment_id", {
				header: `${t("order")} #`,
				cell: (info) => (
					<TableCell
						variant="link"
						props={{ to: "/orders/$id", params: { id: info.row.original.order_item.order.id } }}
						value={info.getValue()}
					/>
				),
			}),
			columnHelper.accessor("order_item.name", {
				header: t("product"),
				cell: (info) => <TableCell variant="name" value={info.getValue()} />,
			}),
			columnHelper.accessor("purchasable.length", {
				header: t("length"),
				cell: (info) => <TableCell variant="millimeter" value={info.getValue()} />,
			}),
			columnHelper.accessor("purchasable.width", {
				header: t("width"),
				cell: (info) => <TableCell variant="millimeter" value={info.getValue()} />,
			}),
			columnHelper.accessor("supplier.label", {
				header: t("supplier"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("total_cost.formatted", {
				header: t("purchase_value"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("quantity", {
				header: t("quantity"),
				cell: (info) => <TableCell variant="default" value={info.getValue().toString()} />,
			}),
			columnHelper.accessor("order_item.order.production_date", {
				header: t("production_date"),
				cell: (info) => (
					<TableCell variant="date" value={info.getValue()} props="production_date" />
				),
			}),
			columnHelper.accessor("created_at", {
				header: t("created_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="created_at" />,
			}),
			columnHelper.accessor("updated_at", {
				header: t("updated_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="updated_at" />,
			}),
		];
	}, [t]);

	const tableProps = useTableProps({
		id: "purchase-orders-items",
		pagination: { id: Route.id, rowCount: items.meta.total },
		rowSelection: true,
		columnVisibility: { id: false, created_at: false, updated_at: false },
	});

	const table = useReactTable({
		data: items.data,
		columns,
		getRowId: (row) => row.id,
		getCoreRowModel: getCoreRowModel(),
		...tableProps.props,
	});

	const selected = useMemo(
		() => tableProps.selected.filter((id) => items.data.some((item) => item.id === id)),
		[tableProps.selected, items.data],
	);

	const formattedSelectedTotalCost = useMemo(() => {
		const selectedTotalCost = items.data
			.filter((item) => selected?.includes(item.id))
			.reduce((a, b) => a + Number(b.total_cost?.amount), 0);

		return formatter({
			lang: i18n.language,
			variant: "currency",
			currency: items.data.at(0)?.total_cost?.currency ?? "EUR",
			value: selectedTotalCost / 100,
		});
	}, [items.data, selected, i18n.language]);

	const rowLink = useMemo(
		() => ({ origin: "order_item.order.id", to: "/orders/$id" }) as const,
		[],
	);

	const filterItems = useMemo(
		() => [
			{
				variant: "default",
				label: t("supplier"),
				value: "supplier",
				items: items.meta.filter_options.supplier,
			} as const,
		],
		[items.meta.filter_options.supplier, t],
	);

	const fastActions = useMemo(
		() => [
			{
				id: "create-purchase-order",
				label: t("create_purchase_order"),
				icon: faEnvelope,
				action: () =>
					fetchClient
						.POST("/api/v1/purchase-orders", {
							body: {
								purchase_order_item_ids: selected,
								status: "created",
								description: null,
							},
						})
						.then(async (v) => {
							await queryClient.invalidateQueries();

							if (v.data?.id) {
								await navigate({ to: "/purchase-orders/$id", params: { id: v.data?.id } });
							}
						}),
			},
		],
		[t, selected, queryClient, navigate],
	);

	return (
		<>
			<TableLayout
				tableProps={{ table, rowLink }}
				topbarProps={{
					title: t("purchase_order_items"),
					modules: { pagination: true, rowSelection: true, columnVisibility: true },
				}}
				filterItems={filterItems}
			/>

			<FastActions
				selected={selected}
				clearSelected={() => table.toggleAllRowsSelected(false)}
				description={`${t("selected_purchase_value")}: ${formattedSelectedTotalCost}`}
				actions={fastActions}
			/>
		</>
	);
}
