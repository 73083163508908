import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { $api } from "~/api";
import { catchNotFound } from "~/api/middleware/error-middleware";
import { Typography } from "~/components/utilities/Typography";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { PageLayout } from "~/layouts/PageLayout";
import { SideBarBlock, SidebarDateItem, SidebarItem } from "~/layouts/utilities/Sidebar";

const orderQueryOptions = (id: string) => {
	return $api.queryOptions("get", "/api/v1/orders/{id}", {
		params: { path: { id } },
	});
};

export const Route = createFileRoute("/_authenticated/orders/$id")({
	component: RouteComponent,
	onError: catchNotFound,
	loader: ({ context, params }) =>
		context.queryClient.ensureQueryData(orderQueryOptions(params.id)),
});

function RouteComponent() {
	const { t } = useTranslation();
	const params = Route.useParams();
	const orderQuery = useSuspenseQuery(orderQueryOptions(params.id));
	const order = orderQuery.data;

	useCustomDocumentTitle(`${t("order")} #${orderQuery.data.increment_id}`);

	return (
		<>
			<PageLayout
				title={`${t("order")} #${orderQuery.data.increment_id}`}
				backLink={{ to: "/orders", children: t("orders") }}
				sidebar={{
					variant: "fixed",
					children: (
						<>
							<SideBarBlock title={t("information")}>
								<SidebarItem label={`${t("order")} #`} value={order.increment_id} />
								<SidebarItem label={t("store")} value={order.store.label} />
							</SideBarBlock>

							<SideBarBlock title={t("additional_information")}>
								<SidebarDateItem variant="placed_at" value={order.placed_at} />
								<SidebarDateItem variant="production_date" value={order.production_date} />
								<SidebarDateItem variant="shipping_date" value={order.shipping_date} />
								<SidebarDateItem variant="delivery_date" value={order.delivery_date} />
								<SidebarDateItem variant="created_at" value={order.created_at} />
								<SidebarDateItem variant="updated_at" value={order.updated_at} />
							</SideBarBlock>
						</>
					),
				}}
			>
				<div className="flex flex-col items-start gap-4">
					<div className="app-background-floating-box w-full p-4">
						<Typography weight="bold" className="mb-0.5">
							{t("customer")}
						</Typography>

						{Object.entries(orderQuery.data.customer).map(([key, value]) => (
							<Typography iconColor="gray" iconSize="sm" key={key}>
								<span className="text-gray-11 text-sm">{key}:</span> {value}
							</Typography>
						))}
					</div>

					<div className="app-background-floating-box w-full p-4">
						<Typography weight="bold" className="mb-0.5">
							{t("billing_address")}
						</Typography>

						{Object.entries(orderQuery.data.billing_address).map(([key, value]) => (
							<Typography iconColor="gray" iconSize="sm" key={key}>
								<span className="text-gray-11 text-sm">{key}:</span> {value}
							</Typography>
						))}
					</div>

					<div className="app-background-floating-box w-full p-4">
						<Typography weight="bold" className="mb-0.5">
							{t("shipping_address")}
						</Typography>

						{Object.entries(orderQuery.data.shipping_address).map(([key, value]) => (
							<Typography iconColor="gray" iconSize="sm" key={key}>
								<span className="text-gray-11 text-sm">{key}:</span> {value}
							</Typography>
						))}
					</div>
				</div>
			</PageLayout>
		</>
	);
}
