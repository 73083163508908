import type { ReactNode } from "react";
import { CommandProvider } from "~/components/command/provider";
import { Compose } from "~/components/utilities/Compose";
import { MobileNavigation } from "~/components/utilities/layout/navigation/MobileNavigation";
import { Navigation } from "~/components/utilities/layout/navigation/Navigation";
import { Toaster } from "~/components/utilities/toast/toaster";
import { cn } from "~/hooks/use-tailwind";

export function LayoutComponent({ children }: { children: ReactNode }) {
	return (
		<Compose components={[CommandProvider]}>
			<div className="mx-auto flex xs:grid min-h-screen max-w-[calc(1792px+16rem)] xs:grid-cols-[auto,_minmax(0,_1fr)] flex-col bg-gradient-to-tr from-70% from-app-background via-primary-1 to-primary-2">
				<Navigation />

				<div className="flex grow flex-col gap-4 [--header-y:46px] [--subheader-y:40px] xs:[--header-y:42px]">
					<header className="sticky top-0 z-2 pt-4">
						<div className="background-floating-box mx-4 flex items-center gap-4 px-4 py-2">
							<MobileNavigation />
							<div className="flex h-[26px] items-center">Search</div>
						</div>
					</header>

					<div
						className={cn(
							"scrollbar-none flex max-h-[calc(100vh-var(--header-y)-2rem-var(--fast-actions-y,0px))] grow snap-y flex-col gap-4 overflow-y-auto safe-motion:transition-all will-change-transform *:snap-start",
						)}
					>
						{children}
					</div>
				</div>
			</div>

			<Toaster />
		</Compose>
	);
}
