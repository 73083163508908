import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { Typography } from "~/components/utilities/Typography";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { useUser } from "~/hooks/use-user";
import { PageLayout } from "~/layouts/PageLayout";
import { SideBarBlock, SidebarDateItem } from "~/layouts/utilities/Sidebar";

export const Route = createFileRoute("/_authenticated/profile")({
	component: RouteComponent,
});

function RouteComponent() {
	const { t } = useTranslation();
	const user = useUser();
	const { created_at, updated_at, ...restUser } = user.data;

	useCustomDocumentTitle(t("profile"));

	return (
		<PageLayout
			title="Profile"
			sidebar={{
				variant: "collapsible",
				children: (
					<SideBarBlock title={t("additional_information")}>
						<SidebarDateItem variant="created_at" value={created_at} />
						<SidebarDateItem variant="updated_at" value={updated_at} />
					</SideBarBlock>
				),
			}}
		>
			<div className="flex flex-col items-start gap-4">
				<div className="app-background-floating-box w-full p-4">
					<Typography weight="bold" className="mb-0.5">
						{t("user")}
					</Typography>

					{Object.entries(restUser).map(([key, value]) => (
						<Typography iconColor="gray" iconSize="sm" key={key}>
							<span className="text-gray-11 text-sm">{key}:</span> {value}
						</Typography>
					))}
				</div>
			</div>
		</PageLayout>
	);
}
