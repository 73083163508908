import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { $api } from "~/api";
import { TableCell } from "~/components/utilities/table/Cell";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { useTableProps } from "~/hooks/use-table";
import { TableLayout } from "~/layouts/TableLayout";
import { paginationSchema } from "~/types/route";

const sortOptions = ["created_at", "-created_at", "updated_at", "-updated_at"] as const;

const searchSchema = paginationSchema.extend({
	filter: z
		.object({
			increment_id: z.string().optional(),
			supplier: z.string().optional(),
			status: z.string().optional(),
		})
		.optional(),
	sort: z.enum(sortOptions).optional(),
});

type SearchParams = z.infer<typeof searchSchema>;

const purchaseOrdersQueryOptions = (params: SearchParams) => {
	return $api.queryOptions("get", "/api/v1/purchase-orders", {
		params: {
			query: {
				page: params.page,
				per_page: params.per_page,
				"filter[increment_id]": params.filter?.increment_id,
				"filter[supplier]": params.filter?.supplier,
				"filter[status]": params.filter?.status,
				sort: params.sort,
			},
		},
	});
};

export const Route = createFileRoute("/_authenticated/purchase-orders/")({
	component: RouteComponent,
	validateSearch: (search) => searchSchema.parse(search),
	loaderDeps: ({ search }) => search,
	loader: ({ context, deps }) =>
		context.queryClient.ensureQueryData(purchaseOrdersQueryOptions(deps)),
});

function RouteComponent() {
	const { t } = useTranslation();
	const searchParams = Route.useSearch();
	const query = useSuspenseQuery(purchaseOrdersQueryOptions(searchParams));
	const orders = query.data;

	useCustomDocumentTitle(`${t("purchase_orders")} (${orders.meta.total})`);

	const columns = useMemo(() => {
		const columnHelper = createColumnHelper<(typeof orders)["data"][number]>();

		return [
			columnHelper.accessor("id", {
				header: t("id"),
				cell: (info) => <TableCell variant="clipboard" value={info.getValue()} />,
			}),
			columnHelper.accessor("increment_id", {
				header: `${t("purchase_order")} #`,
				cell: (info) => (
					<TableCell
						variant="link"
						props={{ to: "/purchase-orders/$id", params: { id: info.row.original.id } }}
						value={info.getValue()}
					/>
				),
			}),
			columnHelper.accessor("status", {
				header: t("status"),
				cell: (info) => (
					<TableCell
						variant="status"
						props={{ variant: "purchase-order", current: info.getValue().value }}
						value={info.getValue().label}
					/>
				),
			}),
			columnHelper.accessor("supplier.label", {
				header: t("supplier"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("description", {
				header: t("description"),
				cell: (info) => <TableCell variant="default" value={info.getValue() || "-"} />,
			}),
			columnHelper.accessor("created_at", {
				header: t("created_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="created_at" />,
			}),
			columnHelper.accessor("updated_at", {
				header: t("updated_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="updated_at" />,
			}),
		];
	}, [t]);

	const tableProps = useTableProps({
		id: "purchase-orders",
		pagination: { id: Route.id, rowCount: orders.meta.total },
		rowSelection: false,
		columnVisibility: { id: false, created_at: false, updated_at: false },
	});

	const table = useReactTable({
		data: orders.data,
		columns,
		getRowId: (row) => row.id,
		getCoreRowModel: getCoreRowModel(),
		...tableProps.props,
	});

	return (
		<TableLayout
			tableProps={{
				table,
				rowLink: { to: "/purchase-orders/$id" },
				sort: ["created_at", "updated_at"],
			}}
			topbarProps={{
				title: t("purchase_orders"),
				modules: { pagination: true, rowSelection: false, columnVisibility: true },
			}}
			filterItems={[
				{
					variant: "input",
					label: `${t("purchase_order")} #`,
					value: "increment_id",
				},
				{
					variant: "default",
					label: t("status"),
					value: "status",
					items: orders.meta.filter_options.status,
				},
				{
					variant: "default",
					label: t("supplier"),
					value: "supplier",
					items: orders.meta.filter_options.supplier,
				},
			]}
		/>
	);
}
