import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import type Common from "~/lang/en/common.json";

export const defaultNS = "common";

declare module "i18next" {
	interface CustomTypeOptions {
		defaultNS: typeof defaultNS;
		resources: {
			common: typeof Common;
		};
	}
}

export default i18next
	.use({
		type: "backend",
		read: async (language: string, namespace: string, callback: (a: null, b: object) => void) => {
			try {
				const file = await import(`./lang/${language}/${namespace}.json`);

				callback(null, file.default);
			} catch (_error) {
				callback(null, {});
			}
		},
	})
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		load: "languageOnly",
		defaultNS: "common",
		fallbackLng: ["en", "nl", "pl"],
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

i18next.on("languageChanged", (lng) => {
	document.documentElement.setAttribute("lang", lng);
});
