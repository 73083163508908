/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as GuestImport } from './routes/_guest'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as AuthenticatedIndexImport } from './routes/_authenticated/index'
import { Route as GuestLoginImport } from './routes/_guest/login'
import { Route as AuthenticatedProfileImport } from './routes/_authenticated/profile'
import { Route as AuthenticatedRestSheetsIndexImport } from './routes/_authenticated/rest-sheets/index'
import { Route as AuthenticatedPurchaseOrdersIndexImport } from './routes/_authenticated/purchase-orders/index'
import { Route as AuthenticatedProductionBatchesIndexImport } from './routes/_authenticated/production-batches/index'
import { Route as AuthenticatedOrdersIndexImport } from './routes/_authenticated/orders/index'
import { Route as AuthenticatedOptimizableItemsIndexImport } from './routes/_authenticated/optimizable-items/index'
import { Route as AuthenticatedMaterialsIndexImport } from './routes/_authenticated/materials/index'
import { Route as AuthenticatedCustomersIndexImport } from './routes/_authenticated/customers/index'
import { Route as AuthenticatedRestSheetsIdImport } from './routes/_authenticated/rest-sheets/$id'
import { Route as AuthenticatedPurchaseOrdersIdImport } from './routes/_authenticated/purchase-orders/$id'
import { Route as AuthenticatedProductionBatchesIdImport } from './routes/_authenticated/production-batches/$id'
import { Route as AuthenticatedOrdersIdImport } from './routes/_authenticated/orders/$id'
import { Route as AuthenticatedMaterialsIdImport } from './routes/_authenticated/materials/$id'
import { Route as AuthenticatedCustomersIdImport } from './routes/_authenticated/customers/$id'
import { Route as AuthenticatedPurchaseOrdersItemsIndexImport } from './routes/_authenticated/purchase-orders/items/index'

// Create/Update Routes

const GuestRoute = GuestImport.update({
  id: '/_guest',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedIndexRoute = AuthenticatedIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const GuestLoginRoute = GuestLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => GuestRoute,
} as any)

const AuthenticatedProfileRoute = AuthenticatedProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedRestSheetsIndexRoute =
  AuthenticatedRestSheetsIndexImport.update({
    id: '/rest-sheets/',
    path: '/rest-sheets/',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedPurchaseOrdersIndexRoute =
  AuthenticatedPurchaseOrdersIndexImport.update({
    id: '/purchase-orders/',
    path: '/purchase-orders/',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedProductionBatchesIndexRoute =
  AuthenticatedProductionBatchesIndexImport.update({
    id: '/production-batches/',
    path: '/production-batches/',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedOrdersIndexRoute = AuthenticatedOrdersIndexImport.update({
  id: '/orders/',
  path: '/orders/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedOptimizableItemsIndexRoute =
  AuthenticatedOptimizableItemsIndexImport.update({
    id: '/optimizable-items/',
    path: '/optimizable-items/',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedMaterialsIndexRoute =
  AuthenticatedMaterialsIndexImport.update({
    id: '/materials/',
    path: '/materials/',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedCustomersIndexRoute =
  AuthenticatedCustomersIndexImport.update({
    id: '/customers/',
    path: '/customers/',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedRestSheetsIdRoute = AuthenticatedRestSheetsIdImport.update({
  id: '/rest-sheets/$id',
  path: '/rest-sheets/$id',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedPurchaseOrdersIdRoute =
  AuthenticatedPurchaseOrdersIdImport.update({
    id: '/purchase-orders/$id',
    path: '/purchase-orders/$id',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedProductionBatchesIdRoute =
  AuthenticatedProductionBatchesIdImport.update({
    id: '/production-batches/$id',
    path: '/production-batches/$id',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedOrdersIdRoute = AuthenticatedOrdersIdImport.update({
  id: '/orders/$id',
  path: '/orders/$id',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedMaterialsIdRoute = AuthenticatedMaterialsIdImport.update({
  id: '/materials/$id',
  path: '/materials/$id',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedCustomersIdRoute = AuthenticatedCustomersIdImport.update({
  id: '/customers/$id',
  path: '/customers/$id',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedPurchaseOrdersItemsIndexRoute =
  AuthenticatedPurchaseOrdersItemsIndexImport.update({
    id: '/purchase-orders/items/',
    path: '/purchase-orders/items/',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/_guest': {
      id: '/_guest'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof GuestImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/profile': {
      id: '/_authenticated/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof AuthenticatedProfileImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_guest/login': {
      id: '/_guest/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof GuestLoginImport
      parentRoute: typeof GuestImport
    }
    '/_authenticated/': {
      id: '/_authenticated/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthenticatedIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/customers/$id': {
      id: '/_authenticated/customers/$id'
      path: '/customers/$id'
      fullPath: '/customers/$id'
      preLoaderRoute: typeof AuthenticatedCustomersIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/materials/$id': {
      id: '/_authenticated/materials/$id'
      path: '/materials/$id'
      fullPath: '/materials/$id'
      preLoaderRoute: typeof AuthenticatedMaterialsIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/orders/$id': {
      id: '/_authenticated/orders/$id'
      path: '/orders/$id'
      fullPath: '/orders/$id'
      preLoaderRoute: typeof AuthenticatedOrdersIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/production-batches/$id': {
      id: '/_authenticated/production-batches/$id'
      path: '/production-batches/$id'
      fullPath: '/production-batches/$id'
      preLoaderRoute: typeof AuthenticatedProductionBatchesIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/purchase-orders/$id': {
      id: '/_authenticated/purchase-orders/$id'
      path: '/purchase-orders/$id'
      fullPath: '/purchase-orders/$id'
      preLoaderRoute: typeof AuthenticatedPurchaseOrdersIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/rest-sheets/$id': {
      id: '/_authenticated/rest-sheets/$id'
      path: '/rest-sheets/$id'
      fullPath: '/rest-sheets/$id'
      preLoaderRoute: typeof AuthenticatedRestSheetsIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/customers/': {
      id: '/_authenticated/customers/'
      path: '/customers'
      fullPath: '/customers'
      preLoaderRoute: typeof AuthenticatedCustomersIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/materials/': {
      id: '/_authenticated/materials/'
      path: '/materials'
      fullPath: '/materials'
      preLoaderRoute: typeof AuthenticatedMaterialsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/optimizable-items/': {
      id: '/_authenticated/optimizable-items/'
      path: '/optimizable-items'
      fullPath: '/optimizable-items'
      preLoaderRoute: typeof AuthenticatedOptimizableItemsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/orders/': {
      id: '/_authenticated/orders/'
      path: '/orders'
      fullPath: '/orders'
      preLoaderRoute: typeof AuthenticatedOrdersIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/production-batches/': {
      id: '/_authenticated/production-batches/'
      path: '/production-batches'
      fullPath: '/production-batches'
      preLoaderRoute: typeof AuthenticatedProductionBatchesIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/purchase-orders/': {
      id: '/_authenticated/purchase-orders/'
      path: '/purchase-orders'
      fullPath: '/purchase-orders'
      preLoaderRoute: typeof AuthenticatedPurchaseOrdersIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/rest-sheets/': {
      id: '/_authenticated/rest-sheets/'
      path: '/rest-sheets'
      fullPath: '/rest-sheets'
      preLoaderRoute: typeof AuthenticatedRestSheetsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/purchase-orders/items/': {
      id: '/_authenticated/purchase-orders/items/'
      path: '/purchase-orders/items'
      fullPath: '/purchase-orders/items'
      preLoaderRoute: typeof AuthenticatedPurchaseOrdersItemsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedRouteChildren {
  AuthenticatedProfileRoute: typeof AuthenticatedProfileRoute
  AuthenticatedIndexRoute: typeof AuthenticatedIndexRoute
  AuthenticatedCustomersIdRoute: typeof AuthenticatedCustomersIdRoute
  AuthenticatedMaterialsIdRoute: typeof AuthenticatedMaterialsIdRoute
  AuthenticatedOrdersIdRoute: typeof AuthenticatedOrdersIdRoute
  AuthenticatedProductionBatchesIdRoute: typeof AuthenticatedProductionBatchesIdRoute
  AuthenticatedPurchaseOrdersIdRoute: typeof AuthenticatedPurchaseOrdersIdRoute
  AuthenticatedRestSheetsIdRoute: typeof AuthenticatedRestSheetsIdRoute
  AuthenticatedCustomersIndexRoute: typeof AuthenticatedCustomersIndexRoute
  AuthenticatedMaterialsIndexRoute: typeof AuthenticatedMaterialsIndexRoute
  AuthenticatedOptimizableItemsIndexRoute: typeof AuthenticatedOptimizableItemsIndexRoute
  AuthenticatedOrdersIndexRoute: typeof AuthenticatedOrdersIndexRoute
  AuthenticatedProductionBatchesIndexRoute: typeof AuthenticatedProductionBatchesIndexRoute
  AuthenticatedPurchaseOrdersIndexRoute: typeof AuthenticatedPurchaseOrdersIndexRoute
  AuthenticatedRestSheetsIndexRoute: typeof AuthenticatedRestSheetsIndexRoute
  AuthenticatedPurchaseOrdersItemsIndexRoute: typeof AuthenticatedPurchaseOrdersItemsIndexRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedProfileRoute: AuthenticatedProfileRoute,
  AuthenticatedIndexRoute: AuthenticatedIndexRoute,
  AuthenticatedCustomersIdRoute: AuthenticatedCustomersIdRoute,
  AuthenticatedMaterialsIdRoute: AuthenticatedMaterialsIdRoute,
  AuthenticatedOrdersIdRoute: AuthenticatedOrdersIdRoute,
  AuthenticatedProductionBatchesIdRoute: AuthenticatedProductionBatchesIdRoute,
  AuthenticatedPurchaseOrdersIdRoute: AuthenticatedPurchaseOrdersIdRoute,
  AuthenticatedRestSheetsIdRoute: AuthenticatedRestSheetsIdRoute,
  AuthenticatedCustomersIndexRoute: AuthenticatedCustomersIndexRoute,
  AuthenticatedMaterialsIndexRoute: AuthenticatedMaterialsIndexRoute,
  AuthenticatedOptimizableItemsIndexRoute:
    AuthenticatedOptimizableItemsIndexRoute,
  AuthenticatedOrdersIndexRoute: AuthenticatedOrdersIndexRoute,
  AuthenticatedProductionBatchesIndexRoute:
    AuthenticatedProductionBatchesIndexRoute,
  AuthenticatedPurchaseOrdersIndexRoute: AuthenticatedPurchaseOrdersIndexRoute,
  AuthenticatedRestSheetsIndexRoute: AuthenticatedRestSheetsIndexRoute,
  AuthenticatedPurchaseOrdersItemsIndexRoute:
    AuthenticatedPurchaseOrdersItemsIndexRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

interface GuestRouteChildren {
  GuestLoginRoute: typeof GuestLoginRoute
}

const GuestRouteChildren: GuestRouteChildren = {
  GuestLoginRoute: GuestLoginRoute,
}

const GuestRouteWithChildren = GuestRoute._addFileChildren(GuestRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof GuestRouteWithChildren
  '/profile': typeof AuthenticatedProfileRoute
  '/login': typeof GuestLoginRoute
  '/': typeof AuthenticatedIndexRoute
  '/customers/$id': typeof AuthenticatedCustomersIdRoute
  '/materials/$id': typeof AuthenticatedMaterialsIdRoute
  '/orders/$id': typeof AuthenticatedOrdersIdRoute
  '/production-batches/$id': typeof AuthenticatedProductionBatchesIdRoute
  '/purchase-orders/$id': typeof AuthenticatedPurchaseOrdersIdRoute
  '/rest-sheets/$id': typeof AuthenticatedRestSheetsIdRoute
  '/customers': typeof AuthenticatedCustomersIndexRoute
  '/materials': typeof AuthenticatedMaterialsIndexRoute
  '/optimizable-items': typeof AuthenticatedOptimizableItemsIndexRoute
  '/orders': typeof AuthenticatedOrdersIndexRoute
  '/production-batches': typeof AuthenticatedProductionBatchesIndexRoute
  '/purchase-orders': typeof AuthenticatedPurchaseOrdersIndexRoute
  '/rest-sheets': typeof AuthenticatedRestSheetsIndexRoute
  '/purchase-orders/items': typeof AuthenticatedPurchaseOrdersItemsIndexRoute
}

export interface FileRoutesByTo {
  '': typeof GuestRouteWithChildren
  '/profile': typeof AuthenticatedProfileRoute
  '/login': typeof GuestLoginRoute
  '/': typeof AuthenticatedIndexRoute
  '/customers/$id': typeof AuthenticatedCustomersIdRoute
  '/materials/$id': typeof AuthenticatedMaterialsIdRoute
  '/orders/$id': typeof AuthenticatedOrdersIdRoute
  '/production-batches/$id': typeof AuthenticatedProductionBatchesIdRoute
  '/purchase-orders/$id': typeof AuthenticatedPurchaseOrdersIdRoute
  '/rest-sheets/$id': typeof AuthenticatedRestSheetsIdRoute
  '/customers': typeof AuthenticatedCustomersIndexRoute
  '/materials': typeof AuthenticatedMaterialsIndexRoute
  '/optimizable-items': typeof AuthenticatedOptimizableItemsIndexRoute
  '/orders': typeof AuthenticatedOrdersIndexRoute
  '/production-batches': typeof AuthenticatedProductionBatchesIndexRoute
  '/purchase-orders': typeof AuthenticatedPurchaseOrdersIndexRoute
  '/rest-sheets': typeof AuthenticatedRestSheetsIndexRoute
  '/purchase-orders/items': typeof AuthenticatedPurchaseOrdersItemsIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/_guest': typeof GuestRouteWithChildren
  '/_authenticated/profile': typeof AuthenticatedProfileRoute
  '/_guest/login': typeof GuestLoginRoute
  '/_authenticated/': typeof AuthenticatedIndexRoute
  '/_authenticated/customers/$id': typeof AuthenticatedCustomersIdRoute
  '/_authenticated/materials/$id': typeof AuthenticatedMaterialsIdRoute
  '/_authenticated/orders/$id': typeof AuthenticatedOrdersIdRoute
  '/_authenticated/production-batches/$id': typeof AuthenticatedProductionBatchesIdRoute
  '/_authenticated/purchase-orders/$id': typeof AuthenticatedPurchaseOrdersIdRoute
  '/_authenticated/rest-sheets/$id': typeof AuthenticatedRestSheetsIdRoute
  '/_authenticated/customers/': typeof AuthenticatedCustomersIndexRoute
  '/_authenticated/materials/': typeof AuthenticatedMaterialsIndexRoute
  '/_authenticated/optimizable-items/': typeof AuthenticatedOptimizableItemsIndexRoute
  '/_authenticated/orders/': typeof AuthenticatedOrdersIndexRoute
  '/_authenticated/production-batches/': typeof AuthenticatedProductionBatchesIndexRoute
  '/_authenticated/purchase-orders/': typeof AuthenticatedPurchaseOrdersIndexRoute
  '/_authenticated/rest-sheets/': typeof AuthenticatedRestSheetsIndexRoute
  '/_authenticated/purchase-orders/items/': typeof AuthenticatedPurchaseOrdersItemsIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/profile'
    | '/login'
    | '/'
    | '/customers/$id'
    | '/materials/$id'
    | '/orders/$id'
    | '/production-batches/$id'
    | '/purchase-orders/$id'
    | '/rest-sheets/$id'
    | '/customers'
    | '/materials'
    | '/optimizable-items'
    | '/orders'
    | '/production-batches'
    | '/purchase-orders'
    | '/rest-sheets'
    | '/purchase-orders/items'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/profile'
    | '/login'
    | '/'
    | '/customers/$id'
    | '/materials/$id'
    | '/orders/$id'
    | '/production-batches/$id'
    | '/purchase-orders/$id'
    | '/rest-sheets/$id'
    | '/customers'
    | '/materials'
    | '/optimizable-items'
    | '/orders'
    | '/production-batches'
    | '/purchase-orders'
    | '/rest-sheets'
    | '/purchase-orders/items'
  id:
    | '__root__'
    | '/_authenticated'
    | '/_guest'
    | '/_authenticated/profile'
    | '/_guest/login'
    | '/_authenticated/'
    | '/_authenticated/customers/$id'
    | '/_authenticated/materials/$id'
    | '/_authenticated/orders/$id'
    | '/_authenticated/production-batches/$id'
    | '/_authenticated/purchase-orders/$id'
    | '/_authenticated/rest-sheets/$id'
    | '/_authenticated/customers/'
    | '/_authenticated/materials/'
    | '/_authenticated/optimizable-items/'
    | '/_authenticated/orders/'
    | '/_authenticated/production-batches/'
    | '/_authenticated/purchase-orders/'
    | '/_authenticated/rest-sheets/'
    | '/_authenticated/purchase-orders/items/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  GuestRoute: typeof GuestRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  GuestRoute: GuestRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authenticated",
        "/_guest"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/profile",
        "/_authenticated/",
        "/_authenticated/customers/$id",
        "/_authenticated/materials/$id",
        "/_authenticated/orders/$id",
        "/_authenticated/production-batches/$id",
        "/_authenticated/purchase-orders/$id",
        "/_authenticated/rest-sheets/$id",
        "/_authenticated/customers/",
        "/_authenticated/materials/",
        "/_authenticated/optimizable-items/",
        "/_authenticated/orders/",
        "/_authenticated/production-batches/",
        "/_authenticated/purchase-orders/",
        "/_authenticated/rest-sheets/",
        "/_authenticated/purchase-orders/items/"
      ]
    },
    "/_guest": {
      "filePath": "_guest.tsx",
      "children": [
        "/_guest/login"
      ]
    },
    "/_authenticated/profile": {
      "filePath": "_authenticated/profile.tsx",
      "parent": "/_authenticated"
    },
    "/_guest/login": {
      "filePath": "_guest/login.tsx",
      "parent": "/_guest"
    },
    "/_authenticated/": {
      "filePath": "_authenticated/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/customers/$id": {
      "filePath": "_authenticated/customers/$id.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/materials/$id": {
      "filePath": "_authenticated/materials/$id.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/orders/$id": {
      "filePath": "_authenticated/orders/$id.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/production-batches/$id": {
      "filePath": "_authenticated/production-batches/$id.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/purchase-orders/$id": {
      "filePath": "_authenticated/purchase-orders/$id.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/rest-sheets/$id": {
      "filePath": "_authenticated/rest-sheets/$id.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/customers/": {
      "filePath": "_authenticated/customers/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/materials/": {
      "filePath": "_authenticated/materials/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/optimizable-items/": {
      "filePath": "_authenticated/optimizable-items/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/orders/": {
      "filePath": "_authenticated/orders/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/production-batches/": {
      "filePath": "_authenticated/production-batches/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/purchase-orders/": {
      "filePath": "_authenticated/purchase-orders/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/rest-sheets/": {
      "filePath": "_authenticated/rest-sheets/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/purchase-orders/items/": {
      "filePath": "_authenticated/purchase-orders/items/index.tsx",
      "parent": "/_authenticated"
    }
  }
}
ROUTE_MANIFEST_END */
