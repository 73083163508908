import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faWindows } from "@fortawesome/free-brands-svg-icons";
import { faCommand, faTag } from "@fortawesome/pro-solid-svg-icons";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Command } from "cmdk";
import {
	type FC,
	Fragment,
	type PropsWithChildren,
	type ReactNode,
	useCallback,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { StatusCircleByVariant } from "~/components/StatusCircle";
import { CommandContext } from "~/components/command/context";
import { Dialog } from "~/components/dialog/Default";
import { Icon } from "~/components/utilities/IconVariant";
import { Typography } from "~/components/utilities/Typography";
import { useNavigator } from "~/hooks/use-navigator";

export const CommandProvider: FC<PropsWithChildren> = (props) => {
	const { children } = props;
	const { t } = useTranslation();
	const [open, setOpen] = useState<boolean>(false);

	const [openPurchaseOrderStatuses, setOpenPurchaseOrderStatuses] = useState(false);
	const [openShipmentStatuses, setOpenShipmentStatuses] = useState(false);

	const onKeyDown = useCallback(
		(event: KeyboardEvent) => {
			const inputs = ["input", "textarea"];
			const tagName =
				event.target instanceof HTMLElement ? event.target.tagName.toLowerCase() : undefined;
			const notFormElement = (tagName && !inputs.includes(tagName)) || !tagName;

			if (notFormElement || open) {
				if ((event.metaKey || event.ctrlKey) && event.key === "k") {
					event.preventDefault();
					setOpen((prev) => !prev);
				}
			}
		},
		[open],
	);

	useEffect(() => {
		window.addEventListener("keydown", onKeyDown);

		return () => {
			window.removeEventListener("keydown", onKeyDown);
		};
	}, [onKeyDown]);

	return (
		<CommandContext.Provider value={{ open, setOpen }}>
			{children}

			<Command.Dialog
				open={open}
				onOpenChange={setOpen}
				label={t("global_command_menu")}
				overlayClassName="fixed inset-0 z-3 bg-background/50 backdrop-blur-[2px] rdx-state-open:animate-opacity-in rdx-state-closed:animate-opacity-out"
				contentClassName="fixed bottom-1/2 z-3 flex w-full translate-y-1/2 items-center justify-center rdx-state-open:animate-opacity-scale-in rdx-state-closed:animate-opacity-scale-out"
				className="background-floating-box mx-2 w-full p-4 focus:outline-none sm:max-w-screen-sm md:mx-6"
			>
				<DialogPrimitive.Title asChild>
					<Typography weight="bold" size="lg">
						{t("global_command_menu")}
					</Typography>
				</DialogPrimitive.Title>

				<DialogPrimitive.Description asChild>
					<Typography color="gray" contrast="low">
						{t("global_command_menu_description")}
					</Typography>
				</DialogPrimitive.Description>

				<Command.Input
					className="mt-2 mb-4 w-full border-gray-4 enter:border-gray-7 border-b bg-background p-2 safe-motion:transition-colors focus:outline-none"
					placeholder={t("type_a_command_or_search")}
				/>
				<Command.List>
					<Command.Empty>{t("no_results_found")}</Command.Empty>

					<CommandGroup heading={t("legend")}>
						<CommandItem
							icon={faTag}
							onSelect={() => setOpenPurchaseOrderStatuses((prev) => !prev)}
						>
							{t("purchase_order_statuses")}
						</CommandItem>
						<CommandItem icon={faTag} onSelect={() => setOpenShipmentStatuses((prev) => !prev)}>
							{t("shipment_statuses")}
						</CommandItem>
					</CommandGroup>
				</Command.List>
			</Command.Dialog>

			{open && (
				<Fragment>
					<Dialog
						open={openPurchaseOrderStatuses}
						setOpen={setOpenPurchaseOrderStatuses}
						title={t("purchase_order_statuses")}
						description={t("view_purchase_order_statuses")}
						className="flex flex-col gap-1"
					>
						<StatusCircleByVariant variant="purchase-order" current="cancelled" />
						<StatusCircleByVariant variant="purchase-order" current="created" />
						<StatusCircleByVariant variant="purchase-order" current="purchased" />
						<StatusCircleByVariant variant="purchase-order" current="completed" />
					</Dialog>

					<Dialog
						open={openShipmentStatuses}
						setOpen={setOpenShipmentStatuses}
						title={t("shipment_statuses")}
						description={t("view_shipment_statuses")}
						className="flex flex-col gap-1"
					>
						<StatusCircleByVariant variant="shipment" current="created" />
						<StatusCircleByVariant variant="shipment" current="delivered" />
					</Dialog>
				</Fragment>
			)}
		</CommandContext.Provider>
	);
};

function CommandGroup(props: { heading: ReactNode; children: ReactNode }) {
	return (
		<Command.Group className="mb-4 text-gray-11 text-sm *:mb-1 last:mb-0 last:*:mb-0" {...props} />
	);
}

interface CommandItemProps {
	children: ReactNode;
	icon: IconProp;
	shortcut?: string;
	metaKey?: boolean;
	shiftKey?: boolean;
	onSelect: () => void;
}

function CommandItem(props: CommandItemProps) {
	const { children, icon, shortcut, metaKey, shiftKey, onSelect } = props;
	const { isMac } = useNavigator();

	return (
		<Command.Item
			className="mb-1 flex items-center gap-2 rounded p-2 text-base text-gray-12 last:mb-0 aria-selected:bg-gray-2"
			onSelect={onSelect}
		>
			{icon && <Icon icon={icon} color="gray" />}

			{children}

			{shortcut && (
				<div className="flex space-x-1">
					{metaKey && (
						<kbd className="flex min-w-[20px] items-center justify-center rounded border border-shades bg-shades-medium-light px-1 py-0.5 font-sans text-xs">
							<Icon icon={isMac ? faCommand : faWindows} />
						</kbd>
					)}

					{shiftKey && (
						<kbd className="flex min-w-[20px] items-center justify-center rounded border border-shades bg-shades-medium-light px-1 py-0.5 font-sans text-xs">
							⇧
						</kbd>
					)}

					{shortcut.split(" ").map((key) => {
						return (
							<kbd
								className="flex min-w-[20px] items-center justify-center rounded border border-shades bg-shades-medium-light px-1 py-0.5 font-sans text-xs"
								key={key}
							>
								{key.toUpperCase()}
							</kbd>
						);
					})}
				</div>
			)}
		</Command.Item>
	);
}
