import {
	faFaceFrownSlight,
	faFaceScream,
	faFaceSwear,
	faFaceUnamused,
	faFrown,
	faFrownOpen,
	faSadCry,
	faSadTear,
} from "@fortawesome/pro-solid-svg-icons";
import { Link, type NotFoundRouteProps, useParams, useSearch } from "@tanstack/react-router";
import sample from "lodash-es/sample";
import { type ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LayoutComponent } from "~/components/layout";
import { Icon } from "~/components/utilities/IconVariant";
import { Typography } from "~/components/utilities/Typography";
import { Anchor } from "~/components/utilities/interactive/Anchor";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { cn } from "~/hooks/use-tailwind";
import { PageLayout } from "~/layouts/PageLayout";

interface NotFoundPageComponentProps extends NotFoundRouteProps {
	withLayout?: boolean;
	fullHeight?: boolean;
	children?: ReactNode;
}

export function NotFoundPageComponent(props: NotFoundPageComponentProps) {
	const { withLayout, fullHeight, children, data } = props;
	const { t } = useTranslation();
	const searchParams = useSearch({ strict: false });
	const params = useParams({ strict: false });

	const title = params.id ? `${t("no_results_found_for")} '${params.id}'` : t("no_results_found");

	useCustomDocumentTitle(title);

	const icon = useMemo(
		() =>
			sample([
				faFaceSwear,
				faFaceUnamused,
				faFaceScream,
				faSadTear,
				faSadCry,
				faFaceFrownSlight,
				faFrownOpen,
				faFrown,
			]),
		[],
	);

	const content = (
		<PageLayout
			title={t("no_results_found")}
			className="px-2"
			wrapperClassName={cn({ "m-4 min-h-[calc(100vh-2rem)]": !!fullHeight })}
		>
			<div className="flex grow flex-col items-center justify-center gap-4 p-4">
				<Icon icon={icon} size="8x" className="text-primary-11" />
				<Typography size="lg">{title}</Typography>

				{typeof data === "string" && (
					<Typography color="gray" contrast="low">
						{data}
					</Typography>
				)}

				{searchParams.filter && (
					<Anchor asChild>
						<Link to="." search={(prev) => ({ ...prev, filter: undefined })}>
							{t("clear_filters")}
						</Link>
					</Anchor>
				)}

				{withLayout || !fullHeight ? (
					<Anchor asChild>
						<Link to="/">{t("dashboard")}</Link>
					</Anchor>
				) : (
					<Anchor asChild>
						<Link to="/login">{t("login")}</Link>
					</Anchor>
				)}

				{children}
			</div>
		</PageLayout>
	);

	return withLayout ? <LayoutComponent>{content}</LayoutComponent> : content;
}
