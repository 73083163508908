import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

type ColorType =
	| "grey"
	| "dark-grey"
	| "purple"
	| "teal"
	| "green"
	| "yellow"
	| "orange"
	| "pink"
	| "red";

function getColor(variant: ColorType) {
	return match(variant)
		.with("grey", () => "lch(63.247 3.577 260.65)")
		.with("dark-grey", () => "lch(50.998 10.678 258.939)")
		.with("purple", () => "lch(48 59.31 288.43)")
		.with("teal", () => "lch(63 38.933 217.444)")
		.with("green", () => "lch(63 45.124 157.253)")
		.with("yellow", () => "lch(80 90 85)")
		.with("orange", () => "lch(63 62.082 61.651)")
		.with("pink", () => "lch(70 45 31.72)")
		.with("red", () => "lch(57.937 66.467 29.09)")
		.exhaustive();
}

type StatusType = {
	variant: "error" | "success" | "default";
	label: string;
	value: string;
	color: string;
};

function getStatusesByVariant(variant: "purchase-order" | "shipment") {
	const { t } = useTranslation();

	return match(variant)
		.returnType<StatusType[]>()
		.with("purchase-order", () => [
			{ variant: "error", label: t("cancelled"), value: "cancelled", color: getColor("red") },
			{ variant: "default", label: t("created"), value: "created", color: getColor("yellow") },
			{ variant: "default", label: t("purchased"), value: "purchased", color: getColor("purple") },
			{ variant: "success", label: t("completed"), value: "completed", color: getColor("green") },
		])
		.with("shipment", () => [
			{ variant: "default", label: t("created"), value: "created", color: getColor("yellow") },
			{ variant: "success", label: t("delivered"), value: "delivered", color: getColor("green") },
		])
		.exhaustive();
}

export interface StatusCircleByVariantProps {
	variant: "purchase-order" | "shipment";
	current: string;
}

export function StatusCircleByVariant({ variant, current }: StatusCircleByVariantProps) {
	const statuses = getStatusesByVariant(variant);

	const [currentStatus, progress] = useMemo(() => {
		const hasError = !!statuses.find((status) => status.variant === "error");
		const hasSuccess = !!statuses.find((status) => status.variant === "success");

		const currentStatus = statuses.find((status) => status.value === current);
		const state =
			statuses.findIndex((status) => status.value === current) +
			(hasError ? 0 : 1) +
			(hasSuccess ? 0 : 1);
		const total = statuses.length + (hasError ? 0 : 1) + (hasSuccess ? 0 : 1);
		const progress = (state / total) * 18.84;

		return [currentStatus, progress];
	}, [statuses, current]);

	if (!currentStatus) return null;

	return (
		<span className="flex items-center gap-2">
			<Circle
				progress={progress}
				label={currentStatus.label}
				color={currentStatus.color}
				variant={currentStatus.variant}
			/>

			<span>{currentStatus.label}</span>
		</span>
	);
}

interface CircleProps {
	progress: number;
	label: string;
	color: string;
	variant?: StatusType["variant"];
}

const Circle = memo(function InnerCircle(props: CircleProps) {
	const { progress, label, color, variant = "default" } = props;

	const icon = match(variant)
		.with("error", () => (
			<path
				className="fill-white"
				stroke="none"
				d="M3.73657 3.73657C4.05199 3.42114 4.56339 3.42114 4.87881 3.73657L5.93941 4.79716L7 5.85775L9.12117 3.73657C9.4366 3.42114 9.94801 3.42114 10.2634 3.73657C10.5789 4.05199 10.5789 4.56339 10.2634 4.87881L8.14225 7L10.2634 9.12118C10.5789 9.4366 10.5789 9.94801 10.2634 10.2634C9.94801 10.5789 9.4366 10.5789 9.12117 10.2634L7 8.14225L4.87881 10.2634C4.56339 10.5789 4.05199 10.5789 3.73657 10.2634C3.42114 9.94801 3.42114 9.4366 3.73657 9.12118L4.79716 8.06059L5.85775 7L3.73657 4.87881C3.42114 4.56339 3.42114 4.05199 3.73657 3.73657Z"
			/>
		))
		.with("success", () => (
			<path
				className="fill-white"
				stroke="none"
				d="M10.951 4.24896C11.283 4.58091 11.283 5.11909 10.951 5.45104L5.95104 10.451C5.61909 10.783 5.0809 10.783 4.74896 10.451L2.74896 8.45104C2.41701 8.11909 2.41701 7.5809 2.74896 7.24896C3.0809 6.91701 3.61909 6.91701 3.95104 7.24896L5.35 8.64792L9.74896 4.24896C10.0809 3.91701 10.6191 3.91701 10.951 4.24896Z"
			/>
		))
		.otherwise(() => null);

	const iconProps = icon
		? { r: "3", strokeWidth: "6", strokeDasharray: "18.84 18.84" }
		: { r: "2", strokeWidth: "4", strokeDasharray: `${progress} 18.84` };

	return (
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none">
			<title>{label}</title>

			<circle
				cx="7"
				cy="7"
				r="6"
				fill="none"
				stroke={color}
				strokeWidth="2"
				strokeDasharray="3.14 0"
				strokeDashoffset="-0.7"
			/>

			<circle
				cx="7"
				cy="7"
				fill="none"
				stroke={color}
				strokeDashoffset="0"
				transform="rotate(-90 7 7)"
				{...iconProps}
			/>

			{icon}
		</svg>
	);
});
