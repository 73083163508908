import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { Slot, Slottable } from "@radix-ui/react-slot";
import type React from "react";
import { forwardRef } from "react";
import { match } from "ts-pattern";
import { useMediaQuery } from "usehooks-ts";
import { Icon, type IconProps } from "~/components/utilities/IconVariant";
import {
	type ActionVariantProps,
	type AnchorVariantProps,
	type ButtonVariantProps,
	type InteractiveIconProps,
	type TypeProps,
	interactiveAction,
	interactiveAnchor,
	interactiveButton,
} from "~/components/utilities/interactive";

interface BaseProps {
	asChild?: boolean;
	isLoading?: boolean;
	hideChildrenOnMobile?: boolean;
}

export type ButtonProps = BaseProps &
	InteractiveIconProps &
	TypeProps &
	React.JSX.IntrinsicElements["button"];

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function ButtonRef(props, ref) {
	const {
		variant,
		size,
		color,
		style,

		startIcon,
		endIcon,
		iconSize,
		iconColor,

		asChild,
		isLoading,
		hideChildrenOnMobile,

		children,
		className,
		...restProps
	} = props;

	const Comp = asChild ? Slot : "button";
	const isMax639 = useMediaQuery("(max-width: 639px)");
	const hideChildren = hideChildrenOnMobile && isMax639;

	return (
		<Comp
			ref={ref}
			disabled={isLoading}
			className={match(variant)
				.with("link", () =>
					interactiveAnchor({
						color: color as AnchorVariantProps["color"],
						className,
					}),
				)
				.with("action", () =>
					interactiveAction({
						color: color as ActionVariantProps["color"],
						size: size as ActionVariantProps["size"],
						style: style as ActionVariantProps["style"],
						className,
					}),
				)
				.otherwise(() =>
					interactiveButton({
						variant: variant as ButtonVariantProps["variant"],
						size: size as ButtonVariantProps["size"],
						color: color as ButtonVariantProps["color"],
						className,
					}),
				)}
			{...restProps}
		>
			{(startIcon || (!(startIcon || endIcon) && isLoading)) && (
				<Icon
					icon={isLoading ? faSpinnerThird : (startIcon as IconProps["icon"])}
					size={iconSize}
					color={iconColor}
					loading={isLoading}
					className={children && !hideChildren ? "mr-2" : undefined}
				/>
			)}

			{!hideChildren && <Slottable>{children}</Slottable>}

			{endIcon && (
				<Icon
					icon={endIcon}
					size={iconSize}
					color={iconColor}
					className={children && !hideChildren ? "ml-2" : undefined}
				/>
			)}
		</Comp>
	);
});
