import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { P, match } from "ts-pattern";
import { Typography } from "~/components/utilities/Typography";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { useUser } from "~/hooks/use-user";
import { PageLayout } from "~/layouts/PageLayout";
import { formatter } from "~/library/utilities";

export const Route = createFileRoute("/_authenticated/")({
	component: RouteComponent,
});

function RouteComponent() {
	const { t, i18n } = useTranslation();
	const user = useUser();

	useCustomDocumentTitle(t("dashboard"));

	const data = [
		{
			name: "Day before yesterday",
			orders: 40,
			money: 30,
		},
		{
			name: "Yesterday",
			orders: 50,
			money: 45,
		},
		{
			name: "Today",
			orders: 55,
			money: 50,
		},
		{
			name: "Tomorrow",
			orders: 75,
			money: 70,
		},
		{
			name: "Day after tomorrow",
			orders: 100,
			money: 95,
		},
		{
			name: "Day after day after tomorrow",
			orders: 130,
			money: 125,
		},
	];

	function formatName(name: string | number) {
		return match(name)
			.with("money", () => "Money")
			.with("orders", () => "Orders")
			.otherwise(() => name);
	}

	function formatValue(name: string | number, value: number | string | Array<number | string>) {
		return match({ name, value })
			.with({ name: "money", value: P.number }, (p) =>
				formatter({
					lang: i18n.language,
					value: p.value * 1000,
					variant: "currency",
					currency: "EUR",
				}),
			)
			.otherwise(() => value);
	}

	return (
		<PageLayout title={t("dashboard")}>
			<Typography variant="h2" className="mb-8">
				{t("welcome")} {user.data.firstname} {t("to")} {t("app_name")}
			</Typography>

			<div className="scrollbar-none overflow-x-auto rounded">
				<ResponsiveContainer aspect={4}>
					<BarChart data={data}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="name" />
						<YAxis />
						<Tooltip
							formatter={(value, name) => [formatValue(name, value), formatName(name)]}
							contentStyle={{
								border: "none",
								borderRadius: "0.25rem",
							}}
						/>
						<Legend formatter={(value) => formatName(value)} />
						<Bar dataKey="money" fill="rgb(var(--color-grass-10))" />
						<Bar dataKey="orders" fill="rgb(var(--color-primary-10))" />
					</BarChart>
				</ResponsiveContainer>
			</div>
		</PageLayout>
	);
}
