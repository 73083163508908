import { useRef } from "react";
import { useIsomorphicLayoutEffect, useUnmount } from "usehooks-ts";

type UseDocumentTitleOptions = {
	preserveTitleOnUnmount?: boolean;
};

export function useCustomDocumentTitle(title: string, options: UseDocumentTitleOptions = {}): void {
	const titleWithSuffix = `${title} | Hemlock`;
	const { preserveTitleOnUnmount = true } = options;
	const defaultTitle = useRef<string | null>(null);

	useIsomorphicLayoutEffect(() => {
		defaultTitle.current = window.document.title;
	}, []);

	useIsomorphicLayoutEffect(() => {
		window.document.title = titleWithSuffix;
	}, [titleWithSuffix]);

	useUnmount(() => {
		if (!preserveTitleOnUnmount && defaultTitle.current) {
			window.document.title = defaultTitle.current;
		}
	});
}
