import {
	faAnglesUpDown,
	faMoonStars,
	faPersonToDoor,
	faQuestion,
	faSunBright,
} from "@fortawesome/pro-solid-svg-icons";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "usehooks-ts";
import { useCommand } from "~/components/command/context";
import { Dialog } from "~/components/dialog/Default";
import { DropDownMenu } from "~/components/utilities/DropDownMenu";
import { Icon } from "~/components/utilities/IconVariant";
import { Typography } from "~/components/utilities/Typography";
import { Button } from "~/components/utilities/interactive/Button";
import { cn } from "~/hooks/use-tailwind";
import { useLogout, useUser } from "~/hooks/use-user";

export function NavigationFooter({ expanded }: { expanded: boolean }) {
	const user = useUser();
	const logout = useLogout();
	const { t, i18n } = useTranslation();
	const { setOpen: setCommandOpen } = useCommand();
	const isMax1023 = useMediaQuery("(max-width: 1023px)");
	const systemMotion = useMediaQuery("(prefers-reduced-motion: reduce)");
	const systemDark = useMediaQuery("(prefers-color-scheme: dark)");
	const [theme, setTheme] = useState<"dark" | "light">(systemDark ? "dark" : "light");
	const [localeDialogOpen, setLocaleDialogOpen] = useState(false);

	useEffect(() => {
		const isDark = document.documentElement.getAttribute("data-theme") === "dark";

		setTheme(isDark ? "dark" : "light");
	}, []);

	const themeChangeHandler = useCallback(async () => {
		document.documentElement.setAttribute("data-motion", "reduce");

		setTheme((prev) => (prev === "light" ? "dark" : "light"));

		document.documentElement.setAttribute("data-theme", theme === "light" ? "dark" : "light");

		localStorage.setItem("hemlock-theme", theme === "light" ? "dark" : "light");

		setTimeout(() => {
			document.documentElement.setAttribute(
				"data-motion",
				systemMotion ? "reduce" : "no-preference",
			);
		}, 150); // this should match the maximum transition duration used in project
	}, [systemMotion, theme]);

	const currentLanguage = i18n.languages[0] ?? i18n.language;

	const letters = useMemo(
		() =>
			[user.data.firstname.at(0), user.data.lastname.split(" ").at(-1)?.at(0)]
				.filter(Boolean)
				.join("")
				.toUpperCase(),
		[user.data.firstname, user.data.lastname],
	);

	const items = useMemo(
		() => [
			{
				label: t("logout"),
				children: (
					<Typography startIcon={faPersonToDoor} iconSize="sm" iconColor="gray">
						{t("logout")}
					</Typography>
				),
				onSelect: () => logout.mutate({}),
			},
			{
				label: t("change_theme"),
				children: (
					<Typography
						startIcon={theme === "light" ? faSunBright : faMoonStars}
						iconSize="sm"
						iconColor="gray"
					>
						{t("change_theme")}
					</Typography>
				),
				onSelect: async () => themeChangeHandler(),
			},
			{
				label: t("open_language_menu"),
				children: (
					<span>
						<span className="mr-2 inline-block w-[1.25em] text-[0.875em] text-gray-9">
							{currentLanguage.toUpperCase()}
						</span>
						{t("open_language_menu")}
					</span>
				),
				onSelect: () => setLocaleDialogOpen((prev) => !prev),
			},
			{
				label: t("open_command_menu"),
				children: (
					<Typography startIcon={faQuestion} iconSize="sm" iconColor="gray">
						{t("open_command_menu")}
					</Typography>
				),
				onSelect: () => setCommandOpen((prev) => !prev),
			},
		],
		[currentLanguage.toUpperCase, logout.mutate, t, setCommandOpen, theme, themeChangeHandler],
	);

	const button = useMemo(
		() => (
			<button
				type="button"
				className="group flex h-[42px] w-full items-center gap-2 rounded enter:bg-gray-3 safe-motion:transition-colors focus:outline-none"
			>
				<div
					className={cn(
						"flex aspect-square h-full items-center justify-center rounded bg-gray-3 font-bold safe-motion:transition-colors group-enter:bg-gray-4",
						{ "lg:ml-1 lg:h-[calc(100%-0.5rem)]": expanded },
					)}
				>
					{letters}
				</div>

				{expanded && (
					<Fragment>
						<div className="flex w-32 flex-col items-start">
							<Typography weight="bold" size="sm" className="truncate">
								{user.data.firstname} {user.data.lastname}
							</Typography>
							<Typography color="gray" contrast="low" size="xs" className="truncate">
								{user.data.email}
							</Typography>
						</div>

						<div className="ml-auto w-8">
							<Icon icon={faAnglesUpDown} size="sm" className="text-gray-9" />
						</div>
					</Fragment>
				)}
			</button>
		),
		[expanded, letters, user.data.email, user.data.firstname, user.data.lastname],
	);

	return (
		<>
			{isMax1023 ? (
				<Dialog trigger={{ children: button, asChild: true }} title="Menu" description="">
					<div className="flex flex-col gap-3">
						{items.map((item) => (
							<Button
								key={item.label}
								variant="action"
								color="white"
								className="text-start"
								onClick={item.onSelect}
							>
								{item.children}
							</Button>
						))}
					</div>
				</Dialog>
			) : (
				<DropDownMenu contentProps={{ side: "right", align: "end" }} items={items}>
					{button}
				</DropDownMenu>
			)}

			<Dialog
				open={localeDialogOpen}
				setOpen={setLocaleDialogOpen}
				width="xs"
				title={t("change_language")}
				description={t("select_a_language_to_change_the_interface_language")}
			>
				<div className="flex flex-col gap-3">
					{i18n.languages.toSorted().map((language) => (
						<Button
							key={language}
							variant="action"
							color="white"
							onClick={async () => {
								await i18n.changeLanguage(language);
								setLocaleDialogOpen(false);
							}}
							className={cn({
								"border-primary-7 bg-gray-3 text-primary-11": currentLanguage === language,
							})}
						>
							{new Intl.DisplayNames([language], { type: "language" }).of(language)}
						</Button>
					))}
				</div>
			</Dialog>
		</>
	);
}
