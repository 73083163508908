import { P, match } from "ts-pattern";

export function getCSSVar(property: string) {
	return (
		Number.parseInt(getComputedStyle(document.documentElement).getPropertyValue(property), 10) || 0
	);
}

export function openInNewTab(url: string) {
	window.open(url, "_blank", "noopener,noreferrer")?.focus();
}

export function getFlagEmoji(countryCode: string) {
	const codePoints = countryCode
		.toUpperCase()
		.split("")
		.map((char) => 127397 + char.charCodeAt(0));

	return String.fromCodePoint(...codePoints);
}

type GenericObject = Record<string, unknown>;

export function combineAndRemoveSubset(obj1: GenericObject, obj2: GenericObject) {
	const isSubset = Object.entries(obj2).every(([key, value]) => obj1[key] === value);

	if (isSubset) {
		const result = { ...obj1 };
		for (const key of Object.keys(obj2)) {
			delete result[key];
		}
		return result;
	}

	return { ...obj1, ...obj2 };
}

type FormatterProps =
	| {
			variant: "meter" | "centimeter" | "millimeter" | "degree" | "kilogram";
			value: number;
			currency?: never;
			digits?: { min?: number; max?: number };
	  }
	| {
			variant: "currency";
			value: number;
			currency: string;
			digits?: never;
	  }
	| {
			variant: "date-time" | "date";
			value: Date;
			currency?: never;
			digits?: never;
	  }
	| {
			variant: "number";
			value: number;
			currency?: never;
			digits?: { min?: number; max?: number };
	  };

export function formatter(props: FormatterProps & { lang: string }) {
	return match(props)
		.returnType<string>()
		.with(
			{ variant: "meter", value: P.number },
			{ variant: "centimeter", value: P.number },
			{ variant: "millimeter", value: P.number },
			{ variant: "degree", value: P.number },
			{ variant: "kilogram", value: P.number },
			(v) => {
				return new Intl.NumberFormat(props.lang, {
					style: "unit",
					unit: v.variant,
					minimumFractionDigits: v.digits?.min ?? 1,
					maximumFractionDigits: v.digits?.max ?? 2,
				}).format(v.value);
			},
		)
		.with({ variant: "currency" }, (v) => {
			return new Intl.NumberFormat(props.lang, {
				currency: v.currency,
				style: "currency",
			}).format(v.value);
		})
		.with({ variant: "date-time" }, { variant: "date" }, (v) => {
			return new Intl.DateTimeFormat(props.lang, {
				day: "2-digit",
				month: "short",
				year: "2-digit",
				hour: v.variant === "date-time" ? "numeric" : undefined,
				minute: v.variant === "date-time" ? "numeric" : undefined,
			}).format(v.value);
		})
		.with({ variant: "number", value: P.number }, (v) => {
			return new Intl.NumberFormat(props.lang, {
				style: "decimal",
				minimumFractionDigits: v.digits?.min ?? 1,
				maximumFractionDigits: v.digits?.max ?? 2,
			}).format(v.value);
		})
		.exhaustive();
}
