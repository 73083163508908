import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { $api } from "~/api";
import { catchNotFound } from "~/api/middleware/error-middleware";
import { TableCell } from "~/components/utilities/table/Cell";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { useTableProps } from "~/hooks/use-table";
import { TableLayout } from "~/layouts/TableLayout";
import { SideBarBlock, SidebarDateItem, SidebarItem } from "~/layouts/utilities/Sidebar";

const customerQueryOptions = (id: string) =>
	$api.queryOptions("get", "/api/v1/customers/{id}", {
		params: { path: { id } },
	});

export const Route = createFileRoute("/_authenticated/customers/$id")({
	component: RouteComponent,
	onError: catchNotFound,
	loader: ({ context, params }) =>
		context.queryClient.ensureQueryData(customerQueryOptions(params.id)),
});

function RouteComponent() {
	const { t } = useTranslation();
	const params = Route.useParams();
	const customerQuery = useSuspenseQuery(customerQueryOptions(params.id));
	const customer = customerQuery.data;

	useCustomDocumentTitle(`${customer.firstname} ${customer.lastname} (${customer.orders.length})`);

	const columns = useMemo(() => {
		const columnHelper = createColumnHelper<(typeof customer.orders)[number]>();

		return [
			columnHelper.accessor("id", {
				header: t("id"),
				cell: (info) => <TableCell variant="clipboard" value={info.getValue()} />,
			}),
			columnHelper.accessor("store", {
				header: t("store"),
				cell: (info) => (
					<TableCell variant="store" props={info.getValue().value} value={info.getValue().label} />
				),
			}),
			columnHelper.accessor("increment_id", {
				header: `${t("order")} #`,
				cell: (info) => (
					<TableCell
						variant="link"
						value={info.getValue()}
						props={{ to: "/orders/$id", params: { id: info.row.original.id } }}
					/>
				),
			}),
			columnHelper.accessor("placed_at", {
				header: t("placed_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="placed_at" />,
			}),
			columnHelper.accessor("production_date", {
				header: t("production_date"),
				cell: (info) => (
					<TableCell variant="date" value={info.getValue()} props="production_date" />
				),
			}),
			columnHelper.accessor("shipping_date", {
				header: t("shipping_date"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="shipping_date" />,
			}),
			columnHelper.accessor("delivery_date", {
				header: t("delivery_date"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="delivery_date" />,
			}),
			columnHelper.accessor("created_at", {
				header: t("created_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="created_at" />,
			}),
			columnHelper.accessor("updated_at", {
				header: t("updated_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="updated_at" />,
			}),
		];
	}, [t]);

	const tableProps = useTableProps({
		id: "customer",
		pagination: false,
		rowSelection: false,
		columnVisibility: { id: false, created_at: false, updated_at: false },
	});

	const table = useReactTable({
		data: customer.orders,
		columns,
		getRowId: (row) => row.id,
		getCoreRowModel: getCoreRowModel(),
		...tableProps.props,
	});

	return (
		<TableLayout
			tableProps={{
				table,
				fallback: t("no_orders_found"),
				rowLink: { origin: "id", to: "/orders/$id" },
			}}
			topbarProps={{
				title: `${customer.firstname} ${customer.lastname}`,
				modules: { pagination: false, rowSelection: false, columnVisibility: true },
				backLink: { to: "/customers", children: t("customers") },
			}}
			sidebar={{
				variant: "fixed",
				children: (
					<>
						<SideBarBlock title={t("information")}>
							<SidebarItem label={t("firstname")} value={customer.firstname} />
							<SidebarItem label={t("lastname")} value={customer.lastname} />
							<SidebarItem label={t("email")} value={customer.email} />
						</SideBarBlock>

						<SideBarBlock title={t("additional_information")}>
							<SidebarDateItem variant="created_at" value={customer.created_at} />
							<SidebarDateItem variant="updated_at" value={customer.updated_at} />
						</SideBarBlock>
					</>
				),
			}}
		/>
	);
}
