import { Outlet, createFileRoute, redirect, stripSearchParams } from "@tanstack/react-router";
import { LayoutComponent } from "~/components/layout";
import { userQueryOptions } from "~/hooks/use-user";
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from "~/types/route";

export const Route = createFileRoute("/_authenticated")({
	beforeLoad: async ({ context, location }) => {
		try {
			await context.queryClient.ensureQueryData(userQueryOptions);
		} catch (_e) {
			throw redirect({ to: "/login", search: { redirect: location.href } });
		}
	},
	search: {
		middlewares: [
			stripSearchParams({
				page: DEFAULT_PAGE_INDEX,
				per_page: DEFAULT_PAGE_SIZE,
				filter: {},
				sort: "",
			}),
		],
	},
	component: () => (
		<LayoutComponent>
			<Outlet />
		</LayoutComponent>
	),
});
