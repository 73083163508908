import { Outlet, createFileRoute, redirect, stripSearchParams } from "@tanstack/react-router";
import { z } from "zod";
import { userQueryOptions } from "~/hooks/use-user";

export const Route = createFileRoute("/_guest")({
	beforeLoad: async ({ context, search }) => {
		const user = await context.queryClient.fetchQuery(userQueryOptions).catch(() => null);
		if (user) throw redirect({ to: search.redirect ?? "/" });
	},
	validateSearch: z.object({ redirect: z.string().default("/").optional() }),
	search: { middlewares: [stripSearchParams({ redirect: "/" })] },
	component: () => <Outlet />,
});
