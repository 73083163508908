import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { $api } from "~/api";
import { TableCell } from "~/components/utilities/table/Cell";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { useTableProps } from "~/hooks/use-table";
import { TableLayout } from "~/layouts/TableLayout";
import { paginationSchema } from "~/types/route";

const sortOptions = ["created_at", "-created_at", "updated_at", "-updated_at"] as const;

const searchSchema = paginationSchema.extend({
	filter: z
		.object({
			email: z.string().optional(),
			name: z.string().optional(),
		})
		.optional(),
	sort: z.enum(sortOptions).optional(),
});

type SearchParams = z.infer<typeof searchSchema>;

const customersQueryOptions = (params: SearchParams) => {
	return $api.queryOptions("get", "/api/v1/customers", {
		params: {
			query: {
				page: params.page,
				per_page: params.per_page,
				"filter[email]": params.filter?.email,
				"filter[name]": params.filter?.name,
				sort: params.sort,
			},
		},
	});
};

export const Route = createFileRoute("/_authenticated/customers/")({
	component: RouteComponent,
	validateSearch: (search) => searchSchema.parse(search),
	loaderDeps: ({ search }) => search,
	loader: ({ context, deps }) => context.queryClient.ensureQueryData(customersQueryOptions(deps)),
});

function RouteComponent() {
	const { t } = useTranslation();
	const searchParams = Route.useSearch();
	const query = useSuspenseQuery(customersQueryOptions(searchParams));
	const customers = query.data;

	useCustomDocumentTitle(`${t("customers")} (${customers.meta.total})`);

	const columns = useMemo(() => {
		const columnHelper = createColumnHelper<(typeof customers)["data"][number]>();

		return [
			columnHelper.accessor("id", {
				header: t("id"),
				cell: (info) => <TableCell variant="clipboard" value={info.getValue()} />,
			}),
			columnHelper.accessor("email", {
				header: t("email"),
				cell: (info) => (
					<TableCell
						variant="link"
						props={{ to: "/customers/$id", params: { id: info.row.original.id } }}
						value={info.getValue()}
					/>
				),
			}),
			columnHelper.accessor("firstname", {
				header: t("firstname"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("lastname", {
				header: t("lastname"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("created_at", {
				header: t("created_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="created_at" />,
			}),
			columnHelper.accessor("updated_at", {
				header: t("updated_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="updated_at" />,
			}),
		];
	}, [t]);

	const tableProps = useTableProps({
		id: "customers",
		pagination: { id: Route.id, rowCount: customers.meta.total },
		rowSelection: false,
		columnVisibility: { id: false, created_at: false, updated_at: false },
	});

	const table = useReactTable({
		data: customers.data,
		columns,
		getRowId: (row) => row.id,
		getCoreRowModel: getCoreRowModel(),
		...tableProps.props,
	});

	return (
		<TableLayout
			tableProps={{ table, sort: ["created_at", "updated_at"], rowLink: { to: "/customers/$id" } }}
			topbarProps={{
				title: t("customers"),
				modules: { pagination: true, rowSelection: false, columnVisibility: true },
			}}
			filterItems={[
				{
					variant: "input",
					label: t("email"),
					value: "email",
				},
				{
					variant: "input",
					label: t("name"),
					value: "name",
				},
			]}
		/>
	);
}
