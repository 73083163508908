import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { $api } from "~/api";
import { TableCell } from "~/components/utilities/table/Cell";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { useTableProps } from "~/hooks/use-table";
import { TableLayout } from "~/layouts/TableLayout";
import { paginationSchema } from "~/types/route";

const sortOptions = [
	"placed_at",
	"-placed_at",
	"production_date",
	"-production_date",
	"shipping_date",
	"-shipping_date",
	"delivery_date",
	"-delivery_date",
] as const;

const searchSchema = paginationSchema.extend({
	filter: z
		.object({
			increment_id: z.string().optional(),
			placed_at: z.string().optional(),
			production_date: z.string().optional(),
			shipping_date: z.string().optional(),
			delivery_date: z.string().optional(),
		})
		.optional(),
	sort: z.enum(sortOptions).optional(),
});

type SearchParams = z.infer<typeof searchSchema>;

const ordersQueryOptions = (params: SearchParams) => {
	return $api.queryOptions("get", "/api/v1/orders", {
		params: {
			query: {
				page: params.page,
				per_page: params.per_page,
				"filter[increment_id]": params.filter?.increment_id,
				"filter[placed_at]": params.filter?.placed_at,
				"filter[production_date]": params.filter?.production_date,
				"filter[shipping_date]": params.filter?.shipping_date,
				"filter[delivery_date]": params.filter?.delivery_date,
				sort: params.sort,
			},
		},
	});
};

export const Route = createFileRoute("/_authenticated/orders/")({
	component: RouteComponent,
	validateSearch: (search) => searchSchema.parse(search),
	loaderDeps: ({ search }) => search,
	loader: ({ context, deps }) => context.queryClient.ensureQueryData(ordersQueryOptions(deps)),
});

function RouteComponent() {
	const { t } = useTranslation();
	const searchParams = Route.useSearch();
	const query = useSuspenseQuery(ordersQueryOptions(searchParams));
	const orders = query.data;

	useCustomDocumentTitle(`${t("orders")} (${orders.meta.total})`);

	const columns = useMemo(() => {
		const columnHelper = createColumnHelper<(typeof orders)["data"][number]>();

		return [
			columnHelper.accessor("id", {
				header: t("id"),
				cell: (info) => <TableCell variant="clipboard" value={info.getValue()} />,
			}),
			columnHelper.accessor("store", {
				header: t("store"),
				cell: (info) => (
					<TableCell variant="store" props={info.getValue().value} value={info.getValue().label} />
				),
			}),
			columnHelper.accessor("increment_id", {
				header: `${t("order")} #`,
				cell: (info) => (
					<TableCell
						variant="link"
						value={info.getValue()}
						props={{ to: "/orders/$id", params: { id: info.row.original.id } }}
					/>
				),
			}),
			columnHelper.accessor("placed_at", {
				header: t("placed_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="placed_at" />,
			}),
			columnHelper.accessor("production_date", {
				header: t("production_date"),
				cell: (info) => (
					<TableCell variant="date" value={info.getValue()} props="production_date" />
				),
			}),
			columnHelper.accessor("shipping_date", {
				header: t("shipping_date"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="shipping_date" />,
			}),
			columnHelper.accessor("delivery_date", {
				header: t("delivery_date"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="delivery_date" />,
			}),
			columnHelper.accessor("created_at", {
				header: t("created_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="created_at" />,
			}),
			columnHelper.accessor("updated_at", {
				header: t("updated_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="updated_at" />,
			}),
		];
	}, [t]);

	const tableProps = useTableProps({
		id: "orders",
		pagination: { id: Route.id, rowCount: orders.meta.total },
		rowSelection: false,
		columnVisibility: { id: false, created_at: false, updated_at: false },
	});

	const table = useReactTable({
		data: orders.data,
		columns,
		getRowId: (row) => row.id,
		getCoreRowModel: getCoreRowModel(),
		...tableProps.props,
	});

	return (
		<TableLayout
			tableProps={{
				table,
				sort: ["placed_at", "production_date", "shipping_date", "delivery_date"],
				rowLink: { to: "/orders/$id" },
			}}
			topbarProps={{
				title: t("orders"),
				modules: { pagination: true, rowSelection: false, columnVisibility: true },
			}}
			filterItems={[
				{
					variant: "input",
					label: `${t("order")} #`,
					value: "increment_id",
				},
				{
					variant: "date",
					label: t("placed_at"),
					value: "placed_at",
				},
				{
					variant: "date",
					label: t("production_date"),
					value: "production_date",
				},
				{
					variant: "date",
					label: t("shipping_date"),
					value: "shipping_date",
				},
				{
					variant: "date",
					label: t("delivery_date"),
					value: "delivery_date",
				},
			]}
		/>
	);
}
